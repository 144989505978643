import React from "react"
import styled from "styled-components"

import variables from "../GlobalVariables"
import NavigationItems from "./NavigationItems/NavigationItems"

const NavItemsContainer = styled.div`
  box-sizing: border-box;
  width: 200px;
  height: 100vh;
  background: var(--darkColor);
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0;
  z-index: 999;

  .Main_Logo {
    padding: 10px;
  }

  @media (max-width: ${variables.mediumScreen}) {
    display: none;
  }
`

const logoBar = () => {
  return (
    <NavItemsContainer>
      <NavigationItems />
    </NavItemsContainer>
  )
}

export default logoBar
