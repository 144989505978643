/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ReactGA from "react-ga"
import "./layout.css"
import Header from "./header"
import styled from "styled-components"
import Particles from "./Background"

const LayoutStyles = styled.div``

const ParticleContainer = styled.div`
  display: block;

  @media (max-width: 501px) {
    display: none;
  }
`

const Layout = ({ children }) => {
  useEffect(() => {
    ReactGA.initialize("UA-151920220-2")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <LayoutStyles>
      <Header siteTitle={data.site.siteMetadata.title} />
      <ParticleContainer>
        <Particles />
      </ParticleContainer>
      <main> {children}</main>
      <footer>
        {new Date().getFullYear()} &#169; Copyright, Daniel Jimenez
      </footer>
    </LayoutStyles>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
