import React from "react"
import { SocialIcon } from "react-social-icons"
import styled from "styled-components"
import variables from "../GlobalVariables"

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${variables.mediumScreen}) {
    flex-direction: row;
    margin: 0 auto;
  }

  @media (max-width: ${variables.smallScreen}) {
    margin: 0 auto;
  }
`
const socialMediaHandles = [
  { url: "https://www.linkedin.com/in/djimenez017/", label: "LinkedIn" },
  { url: "https://github.com/djimenez017", label: "Github" },
  { url: "https://twitter.com/Dann3rs", label: "Twitter" },
]

const socialMediaSites = socialMediaHandles.map(socialMediaHandle => {
  return (
    <SocialIcon
      key={socialMediaHandle.label}
      className="icon"
      url={socialMediaHandle.url}
      target="_blank"
      bgColor="#191919"
      fgColor="#ffffff"
      style={{ height: 50, width: 50, padding: "1px", margin: "3px 3px" }}
    />
  )
})

const socialmedia = props => {
  return (
    <Icon style={{ justifyContent: props.justify || "flex-start" }}>
      {socialMediaSites}
    </Icon>
  )
}

export default socialmedia
