import React, { useState } from "react"

import NavigationItem from "./NavigationItem/NavigationItem"
import styled from "styled-components"
import variables from "../../GlobalVariables"
import SideDrawer from "../HamburgerMenu/SideDrawer"

const NavigationItemsStyles = styled.ul`
  margin: 0 auto;

  /* @media screen and (max-width: ${variables.mediumScreen}) {
  }

  @media screen and (max-width: ${variables.smallScreen}) {
  } */
`

const NavigationItems = props => {
  const [opens, setOpens] = useState(false)

  return (
    <NavigationItemsStyles>
      <NavigationItem to={"/#main"}>Home</NavigationItem>
      <NavigationItem to={"/#about"}>About</NavigationItem>
      <NavigationItem to={"/#projects"}>Projects</NavigationItem>
      <NavigationItem to={"/#blog"}>Blog</NavigationItem>
      <NavigationItem to={"/#contact"}>Contact</NavigationItem>
    </NavigationItemsStyles>
  )
}

export default NavigationItems
