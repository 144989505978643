import React from "react"
import { Link } from "gatsby"
import variables from "../../../GlobalVariables"
import styled from "styled-components"

const NavigationSingle = styled.li`
  list-style: none;
  padding: 20px 1px;
  color: ${variables.clearColor};
  font-size: 24px;
  font-family: var(--customFont);
  width: 100%;

  @media screen and (max-width: ${variables.mediumScreen}) {
    padding: 30px 0;
    text-align: right;
    font-size: 25px;
  }

  @media screen and (max-width: ${variables.smallScreen}) {
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #eeeeee;

  &:hover {
    color: ${variables.accentColor};
    transition: ease 0.4s;
  }
`

const navigationItem = props => {
  return (
    <NavigationSingle>
      <StyledLink to={props.to} id={props.id}>
        {props.children}
      </StyledLink>
    </NavigationSingle>
  )
}

export default navigationItem
