import PropTypes from "prop-types"
import React from "react"

import LogoBar from "../components/Navigation/logoBar"
import NavBar from "../components/Navigation/NavigationItemsBar"
import styled from "styled-components"
import NavigationBar from "./Navigation/NavigationBar"

const HeaderStyles = styled.header`
  /* display: flex;
  justify-content: space-between;
  z-index: 1; */
`

const Header = ({ siteTitle }) => (
  <header>
    <NavigationBar />
    <LogoBar />
    <NavBar />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
