import React, { useState } from "react"
import NavigationItems from "../NavigationItems/NavigationItems"
import variables from "../../GlobalVariables"
import styled from "styled-components"
import SocialMedia from "../../UI/SocialMedia"

const SideDrawer = styled.div`
  top: 8vh;
  right: 0;
  bottom: 0;
  left: 130vw;
  position: fixed;
  background-color: var(--darkColor);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  transition: 250ms;

  .socialMedia {
    display: none;
  }

  @media (max-width: ${variables.mediumScreen}) {
    .socialMedia {
      display: block;
    }

    &.open {
      left: 80vw;
      transition: 250ms;
    }
  }

  @media (max-width: ${variables.smallScreen}) {
    .socialMedia {
      display: block;
    }

    &.open {
      left: 50vw;
    }
  }
`

const Sidedrawer = props => {
  const [closeModal, setCloseModal] = useState(true)

  let attachedClasses = ["sideDrawer"]

  props.toggle
    ? (attachedClasses = ["sideDrawer", "open"])
    : (attachedClasses = ["sideDrawer"])

  return (
    <SideDrawer className={attachedClasses.join(" ")}>
      <NavigationItems />
      <SocialMedia className="socialMedia" />
    </SideDrawer>
  )
}

export default Sidedrawer
