import React from "react"
import variables from "../../GlobalVariables"
import styled from "styled-components"

const Hamburger = styled.div`
  background-color: ${variables.accentColor};
  width: 25px;
  height: 3px;
  margin: 5px;
  display: block;
  z-index: 999;

  .open .line1 {
  }

  .open .line2 {
    opacity: 1;
  }

  .open .line3 {
  }
`
const Hide = styled.div`
  @media screen and (max-width: 1300px) {
  }
`

const Hamburgermenu = props => {
  return (
    <Hide onClick={props.onClick}>
      <Hamburger></Hamburger>
      <Hamburger></Hamburger>
      <Hamburger></Hamburger>
    </Hide>
  )
}

export default Hamburgermenu
