import React from "react"

const logo = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 467">
      <g fill="#ED6F07;">
        <polygon
          className="st0"
          points="363.5,-0.1 188.8,-0.1 -0.2,329.5 378.7,329.5 451.6,222 362.6,222 336.2,261.2 117,261.2 228.7,67.6 
		335.7,67.2 386.3,119.8 479.3,119.8 	"
          fill="#ED6F07"
        />
        <polygon
          className="st0"
          points="276.3,466.6 451.1,466.6 640,136.9 261.2,136.9 188.2,244.4 277.3,244.4 303.7,205.2 522.9,205.2 
		411.1,398.9 304.2,399.3 253.6,346.7 160.6,346.7 	"
          fill="#ED6F07"
        />
      </g>
    </svg>
  )
}

export default logo
