import React, { useState } from "react"
import { Link } from "gatsby"
import variables from "../GlobalVariables"
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu"
import SideDrawer from "./HamburgerMenu/SideDrawer"
import styled from "styled-components"
import Title from "../UI/Title"

const NavigationBarStyle = styled.div`
  display: none;
  box-sizing: border-box;
  z-index: 100;
  width: 100%;
  background: ${variables.darkColor};
  max-height: 7vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 10px;

  @media (max-width: ${variables.mediumScreen}) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .desktopOnly {
      display: none;
    }

    .main-logo {
      padding: 10px;
      color: ${variables.clearColor};
    }

    .main-logo:hover {
      color: ${variables.accentColor};
    }
  }
`

function NavigationBar(props) {
  const [open, setOpen] = useState(false)

  return (
    <NavigationBarStyle>
      <Link to="/" className="main-logo">
        <h1>Daniel Jimenez</h1>
      </Link>
      <HamburgerMenu onClick={() => setOpen(!open)} />
      <SideDrawer toggle={open} />
    </NavigationBarStyle>
  )
}

export default NavigationBar
