import React from "react"
import Particles from "react-particles-js"

const particleOpt = {
  particles: {
    number: {
      value: 60,
    },
    color: {
      value: "#ff7800",
    },
    opacity: {
      value: 0.3,
    },
    size: {
      value: 3,
      anim: {
        enable: true,
        speed: 0.5,
      },
    },
    move: {
      speed: 0.3,
    },
    line_linked: {
      distance: 220,
      color: "#fff",
      opacity: 0.3,
      width: 0.5,
    },
  },
  interactivity: {
    detect_on: "window",
    events: {
      onclick: {
        enable: true,
        mode: "push",
      },
    },
  },
}

const Background = () => {
  return <Particles params={particleOpt} height={"100vh"} />
}

export default Background
